<template>
  <div>
    {{ colToPay }}
  </div>
</template>
<script>

export default {
  props: ['colToPay'],
}
</script>
